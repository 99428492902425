<template>
  <section class="Post">
    <h1>{{ title }}</h1>
    <figure v-if="image">
      <img
        :src="image"
        alt="Post image"
      >
    </figure>
    <div class="content">
      <article>
        <slot />
      </article>
    </div>
  </section>
</template>

<script>
export default {
    name: 'PostVue',
    props: {
        title: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default() {
                return '';
            },
        },
        article: {
            type: String,
            default() {
                return '';
            },
        },
    },
};
</script>

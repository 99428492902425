<template>
  <a
    :class="{[colorScheme]: true }"
    :href="href"
    :target="isNewTab ? '_blank' : '_self'"
    @click="onClick"
  >
    <slot />
  </a>
</template>

<script>
export default {
    name: 'ButtonVue',
    props: {
        colorScheme: {
            type: String,
            default: 'red',
        },
        href: {
            type: String,
            default: '#',
        },
        onClick: {
            type: () => Function,
            default: () => () => {},
        },
        isNewTab: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>
a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

a.red {
  background-color: var(--red);
  color: white;
}

a.white {
  background-color: white ;
  color: black;
}

</style>

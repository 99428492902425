<template>
  <div class="about">
    <post
      title="CHBLACKSMITH - Quem é?"
      :image="image"
    >
      <template v-slot>
        <h2>Uma nova experiência.</h2>
        <p>
          Bem-vindo ao universo de CH Blacksmith, um autor envolto em mistério e repleto de histórias intensas. Mas quem é CH Blacksmith? A verdade é que ninguém sabe. E é exatamente assim que queremos que seja. Este nome não pertence a uma pessoa, mas a uma ideia, uma essência literária que transcende a identidade do autor. CH Blacksmith é uma entidade que escreve para o coração e para a mente, tecendo dramas com a complexidade da vida real, mesclando suspense, romance, amor, tragédia e até mesmo um toque de comédia.
        </p>
        <p>
          CH Blacksmith surgiu com o propósito de oferecer aos leitores uma experiência única, onde o foco está inteiramente nas histórias e nas emoções que elas evocam. A ausência de uma identidade conhecida permite que cada narrativa fale por si mesma, sem preconceitos ou expectativas baseadas no autor. É uma tentativa de permitir que os leitores se conectem diretamente com as palavras, os personagens e as situações, vivendo cada história de forma intensa e autêntica.
        </p>
        <p>
          As obras de CH Blacksmith são conhecidas por sua profundidade emocional e por personagens que, apesar de suas falhas e incertezas, refletem a complexidade humana. As histórias exploram temas universais, como a busca por sentido, o poder transformador das relações humanas e a constante luta entre a luz e a escuridão dentro de cada um de nós. Cada livro é uma jornada que promete tocar o leitor de maneira profunda, oferecendo não apenas entretenimento, mas também reflexão.
        </p>
        <p>
          Se você busca por narrativas que desafiam a norma, que brincam com as fronteiras entre o real e o imaginário, e que te convidam a questionar o que significa ser humano, então as histórias de CH Blacksmith são para você. Aqui, acreditamos no poder das palavras para transformar, inspirar e, acima de tudo, conectar.
        </p>
        <p>
          Explore nossos títulos, deixe-se envolver pela narrativa, e permita que CH Blacksmith se torne uma parte das suas reflexões mais profundas. Porque, no final das contas, o que realmente importa não é quem escreveu a história, mas sim como ela toca seu coração.
        </p>
        <br>
        <Button
          href="https://www.amazon.com.br/Andarilho-Clemente-CH-BLACKSMITH-ebook/dp/B0977RD3C9"
        >
          Leia no kindle
        </Button>
      </template>
    </post>
  </div>
</template>

<script>
import Post from '@/components/Post.vue';
import Button from '@/components/Button.vue';

export default {
    components: {
        Post,
        Button,
    },
    data() {
        return {
            // eslint-disable-next-line global-require
            image: require('@/assets/post-model.jpg'),
        };
    },
};
</script>
